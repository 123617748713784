@tailwind base;
@tailwind components;
@tailwind utilities;

img, svg, video, canvas, audio, iframe, embed, object {
    display: initial;
    vertical-align: unset;
}

a, button, input {
  &:focus {
    outline: none;
  }
}
